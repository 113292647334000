import { useNavigate } from "react-router-dom";
import { FaSlidersH } from "react-icons/fa";
import {
  getEnquiries,
  getStatusList,
  updateEnquiry,
} from "../../redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import {
  enquiries_page_details,
  enquiries_data,
  status_list,
  enquiry_loading,
  updateRedux,
} from "../../redux/commonReducer";
import EntriesCounter from "../../commonComponents/EntriesCounter/EntriesCounter";
import useFilter from "../../utils/Hooks/useFilter";
import Input from "../../commonComponents/Input/Input";
import Table from "../../commonComponents/Table/Table";

import Actions from "../../commonComponents/Actions/Actions";
import EditIcon from "../../assets/icons/EDIT.svg";

import { Dropdown } from "react-bootstrap";
import { useEffect, useState } from "react";
import DatePick from "../../commonComponents/DatePicker/DatePick";

import Select from "../../commonComponents/Select/Select";
import LoadingSpinner from "../../commonComponents/LoadingSpinner/LoadingSpinner";
import moment from "moment";

const ManageEnquiries = () => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [matchedStatus, setMatchedStatus] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = useSelector(enquiries_data);
  const pageData = useSelector(enquiries_page_details);
  const statusData = useSelector(status_list);
  const loading = useSelector(enquiry_loading);

  const enquiryData = [{ name: "Curated" }, { name: "Contact" }];

  const initialFilter = {
    page: 0,
    perPage: 10,
    sortColumn: "createdAt",
    sortValue: -1,
    keyword: "",
    submission_date: "",
    enquiry_type: "",
    status: "",
    is_pagination: "",
  };

  useEffect(() => {
    let statusKey = {
      key: "enquiry_management",
    };
    dispatch(getStatusList(statusKey));
    dispatch(updateRedux({ key: "enquiry_details", value: {} }));
  }, []);

  const [filterData, setFilterData] = useFilter({
    initialFilter,
    dataFetch: (filter) => {
      getSearchDataHandler(filter);
    },
    dependency: [],
  });

  function filterHandler(key, value) {
    let filter;
    switch (key) {
      case "searchBox":
        filter = { ...filterData, ...value, page: 0 };
        break;
      case "clearSearch":
        filter = initialFilter;
        break;
      case "keyword":
        filter = { ...filterData, keyword: value, page: 0 };
        break;
      case "submission_date":
        filter = { ...filterData, submission_date: value, page: 0 };
        break;
      case "enquiry_type":
        filter = { ...filterData, enquiry_type: value, page: 0 };
        break;
      case "status":
        filter = { ...filterData, status: value, page: 0 };
        break;

      case "sort":
        filter = {
          ...filterData,
          sortColumn: value.key,
          sortValue: value.value,
        };
        break;
      case "page":
        filter = { ...filterData, page: value };
        break;
      case "entries":
        filter = { ...filterData, page: 0, perPage: parseInt(value) };
        break;
      default:
    }

    if (!filter) return;
    console.log(filter);
    setFilterData(filter);
    // if (
    //   filter.status === "" &&
    //   filter.enquiry_type ==="" &&
    //   filter.submission_date === ""
    // ) {
    //   setError("Please Select At Least One Field")
    //   return;
    // }else{
    //   setError("")
    // }

    if (
      key !== "submission_date" &&
      key !== "enquiry_type" &&
      key !== "status"
    ) {
      getSearchDataHandler(filter);
    }
  }

  const getStatusColor = (status) => {
    if (status === "New Lead") return "#379777";
    if (status === "Lost Lead") return "#FF4545";
    if (status === "Discussion In Progress") return "#007bff";
    if (status === "Lead Converted") return "#F4CE14";
    return "#6c757d";
  };

  function convertDateFormat(dateStr) {
    const [day, month, year] = dateStr.split('-');
    const dateObj = new Date(year, month - 1, day);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Format the date into DD Mon YYYY format
    const formattedDate = `${day} ${monthNames[dateObj.getMonth()]} ${year}`;

    return formattedDate;
}

  const handleStatusChange = (index, status_name, statusId, dataId) => {
    setMatchedStatus({ [index]: status_name });
    const row = {
      id: dataId,
      status: statusId,
    };
    dispatch(
      updateEnquiry(row, (res) => {
        if (res) {
          getSearchDataHandler(filterData);
        }
      })
    );
  };

  const getSearchDataHandler = (filter) => {
    const enquiriesData = {
      page: filter.page,
      sort_order: filter.sortValue,
      perPage: filter.perPage,
      sort_key: filter.sortColumn,
      keyword: filter?.keyword?.trim() ?? "",
      submission_date: filter?.submission_date
      ? moment(filter?.submission_date).endOf('day').toISOString():"",
      enquiry_type: filter.enquiry_type.name || filter.enquiry_type,
      status: filter.status._id || filter.status,
      is_pagination: "1",
    };

    dispatch(getEnquiries(enquiriesData));
  };

  const columns = [
    { title: "Sl.No", key: "sl_no", width: "10px" },
    {
      title: "Submission Date",
      key: "submission_date",
      sort: true,
      width: "180px",
    },
    {
      title: "Customer Name",
      key: "customer_name",
      sort: true,
      width: "200px",
    },
    { title: "Email Address", key: "email", sort: true, width: "200px" },
    { title: "Phone Number", key: "phone", sort: true, width: "120px" },
    { title: "Enquiry From", key: "enquiry", sort: true, width: "120px" },
    { title: "Status", key: "status", width: "100px" },
    { title: "Action", key: "action", width: "100px" },
  ];

  const content = data?.map((item, i) => {
    const status =
      matchedStatus?.[i] ||
      statusData?.find((s) => s._id === item.status)?.name;
    return {
      sl_no: filterData.page * filterData.perPage + (i + 1),
      submission_date: convertDateFormat(item?.submission_date),
      customer_name:
        item.enquiry_type === "curated" ? item?.name : item.first_name || "N/A",
      email: item.email,
      phone: item.phone,
      enquiry:
        item.enquiry_type.charAt(0).toUpperCase() + item.enquiry_type.slice(1),
      status: (
        <Dropdown>
          <Dropdown.Toggle
            style={{ backgroundColor: getStatusColor(status), color: "#fff" }}
          >
            {status}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {statusData
              ?.filter((s) => s._id !== item.status && s.name !== "New Lead")
              .map((s) => (
                <Dropdown.Item
                  key={s._id}
                  onClick={() => handleStatusChange(i, s.name, s._id, item._id)}
                >
                  {s.name}
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>
      ),
      action: (
        <Actions
          buttons={{ edit: EditIcon }}
          onEdit={() => navigate(`/enquiry-details/${item._id}`)}
        />
      ),
    };
  });

  return (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-body">
            <div className="table__add border-style mb-3">
              <h5 className="card-title">Manage Enquiries</h5>
              <button className="me-2 filter_btn">
                <FaSlidersH onClick={() => setOpen(!open)} size={30} />
              </button>
            </div>

            {open && (
              <div className="col">
                <div className="row row-cols-4 mb-4">
                  <div className="col pe-0">
                    <DatePick
                      className="css-dtp-esc2 mb-3"
                      title="Submission Date"
                      placeholder="DD/MM/YYYY"
                      selected={filterData?.submission_date}
                      onChange={(date) =>
                        setFilterData({ ...filterData, submission_date: date })
                      }
                      dateFormat="dd/MM/yyyy"
                      showMonthDropdown
                      showYearDropdown
                    />
                  </div>
                  <div className="col pe-0">
                    <Select
                      title="Enquiry From"
                      className="css-slc-esc1 mb-3"
                      placeholder={"Select"}
                      options={enquiryData}
                      labelSetter={(o) => o?.name}
                      valueSetter={(option) => option.name}
                      onChange={(e) =>
                        setFilterData({ ...filterData, enquiry_type: e })
                      }
                      value={filterData.enquiry_type}

                      // required={{ message: "Please select packages" }}
                    />
                  </div>
                  <div className="col pe-0">
                    <Select
                      title="Status"
                      className="css-slc-esc1 mb-3"
                      placeholder={"Select"}
                      options={statusData}
                      labelSetter={(o) => o?.name}
                      valueSetter={(option) => option._id}
                      onChange={(e) =>
                        setFilterData({ ...filterData, status: e })
                      }
                      value={filterData.status}
                      // required={{ message: "Please select packages" }}
                    />
                  </div>

                  <div className="col pe-0 preview-imagemargin">
                    <div className="d-flex gap-2">
                      <button
                        className="btn btn-primary "
                        onClick={(e) => {
                          filterHandler("searchBox", filterData);
                        }}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-primary "
                        onClick={(e) => {
                          filterHandler("clearSearch");
                        }}
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                  <div className="col-12">
                    {error && <p className="error_feedback">{error}</p>}
                  </div>
                </div>
              </div>
            )}

            <div className="search__head">
              <div className="count">
                <EntriesCounter
                  value={filterData.perPage || ""}
                  onChange={(e) => {
                    filterHandler("entries", e);
                  }}
                />
              </div>
              <div className="search">
                <Input
                  value={filterData.keyword}
                  type="text"
                  className="css-inp-esc2 search"
                  // icon={GenericSearchIcon}
                  placeholder={"Search Titles"}
                  reverse
                  width="300px"
                  field="keyword"
                  showCloseBtn
                  onChange={(e) => !e && filterHandler("keyword", e)}
                  onSubmit={(e) => {
                    filterHandler("keyword", e);
                  }}
                  onIconClick={(e) => {
                    filterHandler("keyword", e);
                  }}
                />
              </div>
            </div>
            {loading ? (
              <LoadingSpinner height={"200px"} />
            ) : (
              <Table
                titles={columns}
                content={content}
                onSort={(e) => filterHandler("sort", e)}
                page={filterData.page}
                pagesCount={pageData?.pages}
                pageStatus={pageData?.status}
                onPageChange={(e) => {
                  filterHandler("page", e.selected);
                }}
                sortValue={{
                  key: filterData.sortColumn,
                  value: filterData.sortValue,
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageEnquiries;
