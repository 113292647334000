import { Accordion } from "react-bootstrap";
import Select from "../../../commonComponents/Select/Select";
import DatePick from "../../../commonComponents/DatePicker/DatePick";
import Input from "../../../commonComponents/Input/Input";
import TextEditor from "../../../commonComponents/TextEditor/TextEditor";
import { editorConfigEn } from "../../../utils/editorConfig";
import FileUpload from "../../../commonComponents/FileUpload/FileUpload";
import { AiOutlineClose } from "react-icons/ai";
import SupportedFormats from "../../../commonComponents/SupportedFormats/SupportedFormats";
import Table from "../../../commonComponents/Table/Table";
import Actions from "../../../commonComponents/Actions/Actions";
import EditIcon from "../../../assets/icons/EDIT.svg";
import DeleteIcon from "../../../assets/icons/DELETE.svg";

const TravelItinerary = ({
  inputs,
  formPlug,
  errors,
  setErrors,
  inputHandler,
  activityData,
  attractionData,
  inputDataRemoveChangeHandler,
  removeImage,
  fileUploadHandler,
  documentList,
  onAddAdditionalItineraryData,
  itinerary,
  itineraryDataChangeHandler,
  onClearAdditionalItineraryData,
  onEditAttachment,
  onDeleteAttachment,
}) => {
  const hasError = (i) => {
    let flag = false;
    for (let key in errors) {
      if (
        key?.includes("itinerary") &&
        // key?.includes(String(i)) &&
        // key?.includes(`_${i}`) &&
        key?.endsWith(`_${i}`) &&
        errors[key]
      ) {
        flag = true;
      }
    }
    return flag;
  };
  return (
    // console.log(errors,"errors"),
    <div className="card">
      <div className="card-body pt-0">
        <div className="tab-content">
          <div className="tab__header">
            <h2>Trip Confirm Dates</h2>
          </div>
          <div className="row mb-2">
            <div className="col-4">
              <div className="mb-3">
                <DatePick
                  className="css-dtp-esc2 mb-3"
                  title={"Start Date"}
                  placeholder="DD/MM/YYYY"
                  selected={inputs?.start_travel_date}
                  onChange={(e, key) => {
                    inputHandler(e, key);
                  }}
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date()}
                  error={errors?.start_travel_date}
                  inputSocket={formPlug}
                  required={{ message: "Please select start date" }}
                  field={"start_travel_date"}
                  showMonthDropdown
                  showYearDropdown
                />
              </div>
            </div>
            <div className="col-4">
              <div className="mb-3">
                <DatePick
                  className="css-dtp-esc2 mb-3"
                  title={"End Date"}
                  placeholder="DD/MM/YYYY"
                  selected={inputs?.end_travel_date}
                  onChange={(e, key) => {
                    inputHandler(e, key);
                  }}
                  dateFormat="dd/MM/yyyy"
                  minDate={inputs?.start_travel_date}
                  error={errors?.end_travel_date}
                  inputSocket={formPlug}
                  required={{ message: "Please select end date" }}
                  field={"end_travel_date"}
                  showMonthDropdown
                  showYearDropdown
                />
              </div>
            </div>
          </div>
          <div className="tab__header">
            <h2>Itinerary</h2>
          </div>

          <Accordion defaultActiveKey={0}>
            {itinerary?.map((ele, index) => {
              return (  
                <Accordion.Item eventKey={index}>
                  <Accordion.Header>
                    <div className="d-flex justify-content-between w-100 w-full">
                      <div>Day {index + 1} </div>
                      {hasError(index) ? (
                        <div>
                          <span className="error mx-3 align-end">
                            Please resolve the error inside
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="row mb-5">
                      <div className="col-12 ">
                        <Input
                          className="css-inp-esc1 mb-3"
                          label="Day Title"
                          placeholder="Type here"
                          required={{
                            message: "Please enter title",
                          }}
                          field={"title"}
                          inputSocket={formPlug}
                          value={ele?.title}
                          onChange={(e, key) => {
                            itineraryDataChangeHandler(
                              e,
                              key,
                              index,
                              "itinerary"
                            );
                          }}
                          error={errors[`${"itinerary"}_${"title"}_${index}`]}
                        />
                      </div>
                      <div className="col-12">
                        <TextEditor
                          config={editorConfigEn}
                          label={"Day Description"}
                          className={"mb-3"}
                          field={"description"}
                          inputSocket={formPlug}
                          required={{
                            message: "Please enter day description",
                          }}
                          placeholder="Type Here"
                          value={ele?.description}
                          onBlur={(newContent) =>
                            itineraryDataChangeHandler(
                              newContent,
                              "description",
                              index,
                              "itinerary"
                            )
                          }
                          onChange={(e, key) => {
                            itineraryDataChangeHandler(
                              e,
                              key,
                              index,
                              "itinerary"
                            );
                          }}
                          error={
                            errors[`${"itinerary"}_${"description"}_${index}`]
                          }
                        />
                      </div>

                      <div className="col-12 col-md-6">
                        <Select
                          title="Activities"
                          className="css-slc-esc1 mb-3"
                          placeholder={"Select"}
                          options={activityData}
                          labelSetter={(o) => o?.title}
                          valueSetter={(option) => option._id}
                          field={"activity"}
                          inputSocket={formPlug}
                          // required={{ message: "Please select activity" }}
                          Multi2
                          value={ele.activity ? ele.activity : []}
                          onChange={(e, key) => {
                            itineraryDataChangeHandler(
                              e,
                              key,
                              index,
                              "itinerary"
                            );
                          }}
                          error={
                            errors[`${"itinerary"}_${"activity"}_${index}`]
                          }
                        />
                      </div>

                      <div className="raw">
                        <div className="col-12 selectedListData">
                          {ele?.activity?.map((option, i) => (
                            <div key={option} className="mb-3 ">
                              <div className="d-inline-block mb-1">
                                <button
                                  className="selected__item"
                                  type="button"
                                >
                                  {option?.title}
                                  <img src="/assets/img/icons/close-red.svg"  
                                   onClick={() =>
                                    inputDataRemoveChangeHandler(
                                      "activity",
                                      option?._id,
                                      "itinerary",
                                      index
                                    )
                                  }
                                   alt="" />
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="mb-3">
                          <Select
                            title="Attractions"
                            className="css-slc-esc1 mb-3"
                            placeholder={"Select"}
                            options={attractionData}
                            labelSetter={(o) => o?.title}
                            valueSetter={(option) => option._id}
                            field={"attraction"}
                            inputSocket={formPlug}
                            // required={{ message: "Please select attraction" }}
                            Multi2
                            value={ele.attraction ? ele.attraction : []}
                            onChange={(e, key) => {
                              itineraryDataChangeHandler(
                                e,
                                key,
                                index,
                                "itinerary"
                              );
                            }}
                            error={
                              errors[`${"itinerary"}_${"attraction"}_${index}`]
                            }
                          />
                        </div>
                      </div>

                      <div className="raw">
                        <div className="col-12 selectedListData">
                          {ele?.attraction.map((option) => (
                            <div key={option} className="mb-3 ">
                              <div className="d-inline-block mb-1">
                                <button
                                  className="selected__item"
                                  type="button"
                                >
                                  {option?.title}

                                  <img src="/assets/img/icons/close-red.svg"  
                                  onClick={() =>
                                    inputDataRemoveChangeHandler(
                                      "attraction",
                                      option?._id,
                                      "itinerary",
                                      index
                                    )
                                  }
                                   alt="" />
                                  
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <div>
                            <div className="d-flex gap-3">
                              <FileUpload
                                style={{
                                  marginRight: 5,
                                  marginTop: 15,
                                }}
                                className="css-atc-esc5  mt-3"
                                label="Map Image"
                                accept={["jpg", "jpeg", "png"]}
                                icon={"/assets/img/icons/plus_black.svg"}
                                closeIcon={
                                  <AiOutlineClose
                                    size={30}
                                    className="remove-icon"
                                    onClick={() => removeImage("map_image")}
                                  />
                                }
                                onChange={(
                                  e,
                                  isInvalid,
                                  message,
                                  isClosing
                                ) => {
                                  setErrors((s) => ({
                                    ...s,
                                    map_image: "",
                                  }));
                                  fileUploadHandler(
                                    e,
                                    isInvalid,
                                    message,
                                    isClosing,
                                    "map_image",
                                    itineraryDataChangeHandler,
                                    "single",
                                    index,
                                    "itinerary"
                                  );
                                }}
                                error={
                                  errors[
                                    `${"itinerary"}_${"map_image"}_${index}`
                                  ]
                                }
                                width={28}
                                field={"map_image"}
                                id="map_image"
                                maxFileSizeMB={50}
                                multiple={false}
                                showPreview
                                files={ele?.map_image}
                                required={{
                                  message: "Please upload map image",
                                }}
                                inputSocket={formPlug}
                              />
                            </div>
                            <SupportedFormats
                              formats={["jpg", "jpeg", "png"]}
                              maxSize={50}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <Input
                            className="css-inp-esc1 mb-3"
                            label="Map Alt Text"
                            placeholder="Type here"
                            required={{ message: "Please enter map alt" }}
                            field={"map_image_alt"}
                            inputSocket={formPlug}
                            value={ele?.map_image_alt}
                            onChange={(e, key) => {
                              itineraryDataChangeHandler(
                                e,
                                key,
                                index,
                                "itinerary"
                              );
                            }}
                            error={
                              errors[
                                `${"itinerary"}_${"map_image_alt"}_${index}`
                              ]
                            }
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <div>
                            <div className="d-flex gap-3">
                              <FileUpload
                                style={{
                                  marginRight: 5,
                                  marginTop: 15,
                                }}
                                className="css-atc-esc5  mt-3"
                                label="Mobile App image"
                                accept={["jpg", "jpeg", "png"]}
                                icon={"/assets/img/icons/plus_black.svg"}
                                closeIcon={
                                  <AiOutlineClose
                                    size={30}
                                    className="remove-icon"
                                    onClick={() =>
                                      removeImage("mobile_map_image")
                                    }
                                  />
                                }
                                onChange={(
                                  e,
                                  isInvalid,
                                  message,
                                  isClosing
                                ) => {
                                  setErrors((s) => ({
                                    ...s,
                                    mobile_map_image: "",
                                  }));
                                  fileUploadHandler(
                                    e,
                                    isInvalid,
                                    message,
                                    isClosing,
                                    "mobile_map_image",
                                    itineraryDataChangeHandler,
                                    "single",
                                    index,
                                    "itinerary"
                                  );
                                }}
                                error={
                                  errors[
                                    `${"itinerary"}_${"mobile_map_image"}_${index}`
                                  ]
                                }
                                width={28}
                                field={"mobile_map_image"}
                                id="mobile_map_image"
                                maxFileSizeMB={50}
                                multiple={false}
                                showPreview
                                files={ele?.mobile_map_image}
                                required={{
                                  message: "Please upload mobile app image",
                                }}
                                inputSocket={formPlug}
                              />
                            </div>
                            <SupportedFormats
                              formats={["jpg", "jpeg", "png"]}
                              maxSize={50}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <Input
                            className="css-inp-esc1 mb-3"
                            label="Mobile App Image Alt Text"
                            placeholder="Type here"
                            required={{
                              message: "Please enter alt text",
                            }}
                            field={"mobile_map_image_alt"}
                            inputSocket={formPlug}
                            value={ele?.mobile_map_image_alt}
                            onChange={(e, key) => {
                              itineraryDataChangeHandler(
                                e,
                                key,
                                index,
                                "itinerary"
                              );
                            }}
                            error={
                              errors[
                                `${"itinerary"}_${"mobile_map_image_alt"}_${index}`
                              ]
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div class="tab__sub_header mt-4" id="essential_documents">
                      <h2>Add Essential Documents</h2>
                    </div>
                    <div className="grid__items col-3">
                      <div className="item">
                        <Select
                          title="Document type"
                          className="css-slc-esc1 mb-3"
                          placeholder={"Select"}
                          options={documentList}
                          labelSetter={(o) => o?.label}
                          valueSetter={(o) => o.value}
                          field={"type"}
                          inputSocket={formPlug}
                          value={ele?.type != "" ? ele?.type : ""}
                          onChange={(e, key) => {
                            itineraryDataChangeHandler(
                              e,
                              key,
                              index,
                              "itinerary"
                            );
                          }}
                          error={errors[`${"itinerary"}_${"type"}_${index}`]}
                        />
                      </div>
                      <div className="item">
                        <Input
                          className="css-inp-esc1 mb-3"
                          label="Title"
                          placeholder="Type here"
                          field={"doc_title"}
                          inputSocket={formPlug}
                          value={ele?.doc_title}
                          onChange={(e, key) => {
                            itineraryDataChangeHandler(
                              e,
                              key,
                              index,
                              "itinerary"
                            );
                          }}
                          error={
                            errors[`${"itinerary"}_${"doc_title"}_${index}`]
                          }
                        />
                      </div>
                      <div className="item">
                        <FileUpload
                          className="css-atc-esc1 preview-imagemargin"
                          buttonLabel="Upload Document"
                          accept={[
                            "jpg",
                            "jpeg",
                            "png",
                            "pdf",
                            "application/pdf",
                          ]}
                          onChange={(e, isInvalid, message, isClosing) => {
                            setErrors((s) => ({
                              ...s,
                              item: "",
                            }));
                            fileUploadHandler(
                              e,
                              isInvalid,
                              message,
                              isClosing,
                              "item",
                              itineraryDataChangeHandler,
                              "single",
                              index,
                              "itinerary"
                            );
                          }}
                          // loader={imageLoader}
                          error={errors[`${"itinerary"}_${"item"}_${index}`]}
                          id="item"
                          maxFileSizeMB={100}
                          showPreview
                          files={ele?.item}
                        />
                      </div>
                      <div className="item grid-3">
                        <TextEditor
                          config={editorConfigEn}
                          label={"Description"}
                          className={"mb-3"}
                          field={"doc_description"}
                          inputSocket={formPlug}
                          placeholder="Type Here"
                          value={ele?.doc_description}
                          onBlur={(newContent) =>
                            itineraryDataChangeHandler(
                              newContent,
                              "doc_description",
                              index,
                              "itinerary"
                            )
                          }
                          onChange={(e, key) => {
                            itineraryDataChangeHandler(
                              e,
                              key,
                              index,
                              "itinerary"
                            );
                          }}
                          error={
                            errors[
                              `${"itinerary"}_${"doc_description"}_${index}`
                            ]
                          }
                        />
                      </div>
                    </div>
                    <div className="col-12 mt-3">
                      <button
                        type="submit"
                        className="btn btn-primary me-3"
                        onClick={() => onAddAdditionalItineraryData(ele, index)}
                      >
                        Add
                      </button>
                      <button
                        type="submit"
                        className="btn btn-danger "
                        onClick={() =>
                          onClearAdditionalItineraryData(ele, index)
                        }
                      >
                        Cancel
                      </button>
                    </div>
                    {ele?.attachments?.length ? (
                      <Table
                        className="css-tbl-esc1 mt-3"
                        titles={[
                          { title: "Sl No", key: "sl_no", width: "10px" },

                          { title: "Type", key: "type", width: "200px" },
                          {
                            title: "Title",
                            key: "doc_title",
                            width: "300px",
                          },
                          {
                            title: "Description",
                            key: "doc_description",
                            width: "450px",
                          },
                          { title: "Action", key: "action", width: "10px" },
                        ]}
                        content={ele?.attachments?.map((item, i) => {
                          return {
                            sl_no: i + 1,
                            doc_title: item.doc_title,
                            type: item?.type?.label,
                            doc_description: (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: item?.doc_description,
                                }}
                              ></span>
                            ),
                            action: (
                              <Actions
                                buttons={{
                                  edit: EditIcon,
                                  delete: DeleteIcon,
                                }}
                                onEdit={() => {
                                  onEditAttachment(index, item, i);
                                }}
                                onDelete={() => {
                                  onDeleteAttachment(index, i);
                                }}
                              />
                            ),
                          };
                        })}
                        sortValue={{ key: "", value: "" }}
                        onSort={() => {}}
                        page={""}
                        pagesCount={""}
                        onPageChange={() => {}}
                        pageStatus={""}
                        top
                      />
                    ) : (
                      <></>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </div>
      </div>
    </div>
  );
};
export default TravelItinerary;
